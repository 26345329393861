import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="careers">
    <StyledContainer>
      <Subtitle>Careers</Subtitle>
        <SectionTitle>We're hiring</SectionTitle>
          <p style={{ textAlign: "center" }}>If you are a talented software engineer with an interest in emerging technologies and finance, please <StyledLink href="mailto:info@koratas.com">send us your resume</StyledLink>.</p>
    </StyledContainer>
  </Section>
)

const StyledLink = styled.a`
  color: ${props => props.theme.color.secondary};
`

export default Features

const StyledAnd = styled.span`
  font-family: Baskerville, 'Goudy Old Style', Palatino, 'Book Antiqua', serif;
  font-style: italic;
  font-weight: normal;
`
const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  padding-bottom: 130px;
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
